import * as React from 'react';

// Data
import config from '../data/general';

const _PLANS = {
  month: {
    "1-team-free": 0,
    "1-team": 19,
    "5-team": 49,
    "10-team": 89,
    "25-team": 199,
    "team-custom": -1
  },
  year: {
    "1-team-free": 0,
    "1-team": parseInt(19*0.6),
    "5-team": parseInt(49*0.6),
    "10-team": parseInt(89*0.6),
    "25-team": parseInt(199*0.6),
    "team-custom": -1
  }
};

const Tick = () => {
  return (
    <svg class="mr-2.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
    </svg>
  );
};

const PricingBlock = ({ interval = "year", setBillingInterval }) => {
  const [plan, setPlan] = React.useState("1-team");
  const tabNotActiveClass = "cursor-pointer focus:outline-none border-gray-200 bg-white text-gray-900 hover:bg-gray-50 flex items-center justify-center rounded-md border py-3 px-3 text-sm font-medium sm:flex-1";
  const tabActiveClass = "cursor-pointer focus:outline-none border-transparent bg-amblue text-white hover:opacity-90 flex items-center justify-center rounded-md border py-3 px-3 text-sm font-medium sm:flex-1";
  const containerSelected = "z-10 border-gray-200 bg-gray-100 relative flex cursor-pointer border p-4 focus:outline-none items-center";
  const containerNotSelected = "border-gray-200 relative flex cursor-pointer border p-4 focus:outline-none items-center";
  const notSelectedRadio = "border-gray-300 bg-white mt-0.5 flex h-4 w-4 cursor-pointer items-center justify-center rounded-full border";
  const selectedRadio = "border-transparent bg-gray-900 mt-0.5 flex h-4 w-4 cursor-pointer items-center justify-center rounded-full border";

  const getPrice = (plan, onlyDigits = false) => {
    if (_PLANS[interval][plan] === -1) {
      return "Custom";
    }
    else if (_PLANS[interval][plan] === 0) {
      return "Free";
    }
    return onlyDigits ? _PLANS[interval][plan] : <>{_PLANS[interval][plan]}<small>.00</small> €</>;
  };

  const currentSelectedPrice = getPrice(plan, true);

  return (
    <div>
      <div class="relative mx-auto mt-8 md:mt-12 md:max-w-5xl">
        <div class="relative rounded-2xl border border-gray-200 bg-white shadow-lg">
          <form action="https://app.adminmate.io/signup" method="GET">
            <div class="p-6 md:px-10 md:py-9 text-center">
              <div class="lg:flex gap-10 mb-12">
                <div class="space-y-6 md:col-span-6 xl:pr-2 flex-1 mb-12 lg:mb-0">
                  <div class="mx-auto flex">
                    <div class="grid gap-3 grid-cols-1 sm:grid-cols-2 flex-1" role="none">
                      <div className={interval === "year" ? tabActiveClass : tabNotActiveClass} onClick={() => setBillingInterval("year")}>
                        Yearly
                        <div className="bg-black text-white text-xs px-2 py-1 rounded-md ml-3">40% OFF</div>
                      </div>
                      <div class={interval === "month" ? tabActiveClass : tabNotActiveClass} onClick={() => setBillingInterval("month")}>
                        Monthly
                      </div>
                    </div>
                  </div>
                  <div role="radiogroup">
                    <div class="-space-y-px rounded-md bg-white" role="none">

                      <input type="hidden" name="plan" value={plan} />

                      <div class={`${plan === "1-team-free" ? containerSelected : containerNotSelected} rounded-tl-md rounded-tr-md`} role="radio" aria-checked="false" tabindex="0" onClick={() => setPlan("1-team-free")}>
                        <span class={plan === "1-team-free" ? selectedRadio : notSelectedRadio}
                        aria-hidden="true">
                          <span class="h-1.5 w-1.5 rounded-full bg-white"></span>
                        </span>
                        <div class="ml-3 flex w-full flex-row justify-between">
                          <div className='text-left'>
                            <span class="text-black text-md block font-medium" id="headlessui-label-8">1 team member (free plan)</span>
                            <span class="text-gray-500 text-xs block font-light" id="headlessui-label-8">You will be switched to this plan after your trial</span>
                          </div>
                          <p class="text-md">
                            <span class="font-medium">{getPrice("1-team-free")}</span>
                          </p>
                        </div>
                      </div>

                      <div class={plan === "1-team" ? containerSelected : containerNotSelected} role="radio" aria-checked="false" tabindex="0" onClick={() => setPlan("1-team")}>
                        <span class={plan === "1-team" ? selectedRadio : notSelectedRadio}
                        aria-hidden="true">
                          <span class="h-1.5 w-1.5 rounded-full bg-white"></span>
                        </span>
                        <div class="ml-3 flex w-full flex-row justify-between">
                          <span class="text-black text-md block font-medium" id="headlessui-label-8">1 team member</span>
                          <p class="text-md">
                            <span class="font-medium">{getPrice("1-team")}</span>
                          </p>
                        </div>
                      </div>

                      <div class={plan === "5-team" ? containerSelected : containerNotSelected} role="radio" aria-checked="false" tabindex="0" onClick={() => setPlan("5-team")}>
                        <span class={plan === "5-team" ? selectedRadio : notSelectedRadio}
                        aria-hidden="true">
                          <span class="h-1.5 w-1.5 rounded-full bg-white"></span>
                        </span>
                        <div class="ml-3 flex w-full flex-row justify-between">
                          <span class="text-black text-md block font-medium" id="headlessui-label-8">Up to 5 team members</span>
                          <p class="text-md">
                            <span class="font-medium">{getPrice("5-team")}</span>
                          </p>
                        </div>
                      </div>

                      <div class={plan === "10-team" ? containerSelected : containerNotSelected} role="radio" aria-checked="false" tabindex="0" onClick={() => setPlan("10-team")}>
                        <span class={plan === "10-team" ? selectedRadio : notSelectedRadio}
                        aria-hidden="true">
                          <span class="h-1.5 w-1.5 rounded-full bg-white"></span>
                        </span>
                        <div class="ml-3 flex w-full flex-row justify-between">
                          <span class="text-black text-md block font-medium" id="headlessui-label-8">Up to 10 team members</span>
                          <p class="text-md">
                            <span class="font-medium">{getPrice("10-team")}</span>
                          </p>
                        </div>
                      </div>

                      <div class={plan === "25-team" ? containerSelected : containerNotSelected} role="radio" aria-checked="false" tabindex="0" onClick={() => setPlan("25-team")}>
                        <span class={plan === "25-team" ? selectedRadio : notSelectedRadio}
                        aria-hidden="true">
                          <span class="h-1.5 w-1.5 rounded-full bg-white"></span>
                        </span>
                        <div class="ml-3 flex w-full flex-row justify-between">
                          <span class="text-black text-md block font-medium" id="headlessui-label-8">Up to 25 team members</span>
                          <p class="text-md">
                            <span class="font-medium">{getPrice("25-team")}</span>
                          </p>
                        </div>
                      </div>

                      <div class={`${plan === "team-custom" ? containerSelected : containerNotSelected} rounded-bl-md rounded-br-md`} role="radio" aria-checked="false" tabindex="0" onClick={() => setPlan("team-custom")}>
                        <span class={plan === "team-custom" ? selectedRadio : notSelectedRadio}
                        aria-hidden="true">
                          <span class="h-1.5 w-1.5 rounded-full bg-white"></span>
                        </span>
                        <div class="ml-3 flex w-full flex-row justify-between">
                          <span class="text-black text-md block font-medium" id="headlessui-label-8">25+ team members</span>
                          <p class="text-md">
                            <span class="font-medium">{getPrice("team-custom")}</span>
                          </p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="col-span-1 justify-center hidden lg:flex">
                  <svg class="mx-auto h-auto w-4 text-gray-300" viewBox="0 0 16 172" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 11)"></line>
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 46)"></line>
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 81)"></line>
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 116)"></line>
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 151)"></line>
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 18)"></line>
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 53)"></line>
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 88)"></line>
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 123)"></line>
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 158)"></line>
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 25)"></line>
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 60)"></line>
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 95)"></line>
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 130)"></line>
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 165)"></line>
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 32)"></line>
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 67)"></line>
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 102)"></line>
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 137)"></line>
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 172)"></line>
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 39)"></line>
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 74)"></line>
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 109)"></line>
                    <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.83205 -0.5547 -0.5547 0.83205 15 144)"></line>
                  </svg>
                </div>
                <div className="flex-1">
                  <div class="mb-6 flex items-end">
                    <p class="text-7xl font-bold text-gray-900 mr-1">{getPrice(plan, true)}</p>
                    {["Free", "Custom"].includes(currentSelectedPrice) === false && (
                      <div class="mb-1">
                        <p class="text-xl font-bold text-gray-600">€ / month</p>
                        {interval === "year" && (
                          <p class="text-md font-base text-gray-400 ml-3">billed annually</p>
                        )}
                      </div>
                    )}
                  </div>
                  <ul class="space-y-3 text-base font-medium text-gray-900 md:col-span-6 xl:pl-2">
                    <li class="flex items-center">
                      <Tick />
                      Data explorer / CRUD
                    </li>
                    <li class="flex items-center">
                      <Tick />
                      Built-in search
                    </li>
                    <li class="flex items-center">
                      <Tick />
                      Filters
                    </li>
                    <li class="flex items-center">
                      <Tick />
                      Segments
                    </li>
                    <li class="flex items-center">
                      <Tick />
                      Dashboards & Charts
                    </li>
                    <li class="flex items-center">
                      <Tick />
                      Environments
                    </li>
                    <li class={`flex items-center${currentSelectedPrice === "Free" ? " not-available" : ""}`}>
                      <Tick />
                      Custom actions
                    </li>
                    <li class={`flex items-center${currentSelectedPrice === "Free" ? " not-available" : ""}`}>
                      <Tick />
                      Teammates & Teams
                    </li>
                    <li class={`flex items-center${currentSelectedPrice === "Free" ? " not-available" : ""}`}>
                      <Tick />
                      Team-based permissions
                    </li>
                  </ul>
                </div>
              </div>
              <button type="submit" class="relative inline-flex items-center justify-center rounded-xl border border-transparent bg-amblue px-8 py-3.5 text-base font-bold text-white transition-all duration-200 hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2">Start your free trial</button>
              <p class="mt-2 text-center text-sm font-medium text-gray-800">Try for free for 7 days</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  // return (
  //   <div className="w-full flex flex-col lg:flex-row fade-in fade-in-second lg:space-x-16 mt-8 md:mt-14" id="pricing">

  //     {/* Pricing Option */}
  //     <div className="flex-1 mb-10">
  //       <div className="border-2 rounded-xl py-4 px-12 text-center bg-white">
  //         <div>
  //           <h3 className="font-semibold text-lg mt-6">Developer</h3>
  //           <div className="relative flex justify-center mt-8 mb-6">
  //             <span className="text-xl -mt-4"></span>
  //             <span className="text-6xl ml-1 leading-5">Free</span>
  //           </div>
  //           <div className="text-gray-600 text-sm">For one user</div>
  //           <p className="mt-8 mb-6 text-gray-600">For tiny & side projects</p>
  //         </div>
  //         <div className="text-left">
  //           <ul className="usps">
  //             <li className="text-gray-800 text-base py-1"><i className="las la-check"></i> Data explorer / CRUD</li>
  //             <li className="text-gray-800 text-base py-1"><i className="las la-check"></i> Built-in search</li>
  //             <li className="text-gray-800 text-base py-1"><i className="las la-check"></i> Filters</li>
  //             <li className="text-gray-800 text-base py-1"><i className="las la-check"></i> Segments</li>
  //             <li className="text-gray-800 text-base py-1"><i className="las la-check"></i> Dashboards & Charts</li>
  //             <li className="text-gray-800 text-base py-1"><i className="las la-check"></i> Environments</li>
  //             <li className="text-gray-800 text-base py-1 grey line-through"><i className="las la-times"></i> Custom actions</li>
  //             <li className="text-gray-800 text-base py-1 grey line-through"><i className="las la-times"></i> Teammates & Teams</li>
  //             {/* <li className="text-gray-800 text-base py-1 grey line-through"><i className="las la-times"></i> Teams</li> */}
  //             <li className="text-gray-800 text-base py-1 grey line-through"><i className="las la-times"></i> Team-based permissions</li>
  //             <li className="text-gray-800 text-base py-1 grey line-through"><i className="las la-times"></i> Activity & Collaboration</li>
  //             <li className="text-gray-800 text-base py-1 grey line-through"><i className="las la-times"></i> 7/7 Support</li>
  //           </ul>
  //         </div>
  //         <div className="my-6">
  //           <a href={config.signup_link} className="block border border-2 border-gray-500 rounded text-base font-semibold p-4 hover:border-amcolor hover:text-amcolor duration-500">Start now</a>
  //         </div>
  //       </div>
  //     </div>

  //     {/* Pricing Option */}
  //     <div className="flex-1 mb-10">
  //       <div className="border-2 border-amcolor rounded-xl py-4 px-12 text-center bg-white relative">
  //         <div>
  //           {/* <div className="bg-amcolor p-2 rounded-b w-36 mx-auto relative top-0 -mt-8 uppercase text-sm font-semibold text-white">Most Popular</div> */}
  //           <h3 className="font-semibold text-lg mt-6">Premium</h3>
  //           <div className="relative flex justify-center mt-8 mb-6">
  //             <span className="text-xl -mt-4">€</span>
  //             <span className="text-6xl ml-1 leading-5">{interval === 'year' ? '25' : '25'}</span>
  //           </div>
  //           <div className="text-gray-600 text-sm">{interval === 'year' ? 'per seat per month, billed annually' : 'per seat per month, billed monthly'}</div>
  //           <p className="mt-8 mb-6 text-gray-600">For startups & scale-ups</p>
  //         </div>
  //         <div className="text-left">
  //           <ul className="usps">
  //             <li className="text-gray-800 text-base py-1"><i className="las la-check"></i> Data explorer / CRUD</li>
  //             <li className="text-gray-800 text-base py-1"><i className="las la-check"></i> Built-in search</li>
  //             <li className="text-gray-800 text-base py-1"><i className="las la-check"></i> Filters</li>
  //             <li className="text-gray-800 text-base py-1"><i className="las la-check"></i> Segments</li>
  //             <li className="text-gray-800 text-base py-1"><i className="las la-check"></i> Dashboards & Charts</li>
  //             <li className="text-gray-800 text-base py-1"><i className="las la-check"></i> Environments</li>
  //             <li className="text-gray-800 text-base py-1"><i className="las la-check"></i> Custom actions</li>
  //             <li className="text-gray-800 text-base py-1"><i className="las la-check"></i> Teammates & Teams</li>
  //             {/* <li className="text-gray-800 text-base py-1"><i className="las la-check"></i> Teams</li> */}
  //             <li className="text-gray-800 text-base py-1"><i className="las la-check"></i> Team-based permissions</li>
  //             <li className="text-gray-800 text-base py-1"><i className="las la-check"></i> Activity & Collaboration</li>
  //             <li className="text-gray-800 text-base py-1"><i className="las la-check"></i> 7/7 Support</li>
  //           </ul>
  //         </div>
  //         <div className="my-6">
  //           <a href={config.signup_link} className="block bg-amcolor rounded text-base font-semibold p-4 hover:bg-amcolor-darker text-white duration-500">Start now</a>
  //         </div>
  //       </div>
  //     </div>

  //   </div>
  // );
};

export default PricingBlock;