import React from 'react';
import Feature1 from '../images/features/feature-1.png';
import Feature2 from '../images/features/feature-2.png';
import Feature3 from '../images/features/feature-3.png';
import Feature4 from '../images/features/feature-4.svg';
import Feature5 from '../images/features/feature-5.png';
import Feature6 from '../images/features/feature-6.svg';
import Feature7 from '../images/features/feature-7.png';
import Feature8 from '../images/features/feature-8.png';
import Feature9 from '../images/features/feature-9.png';
import Feature10 from '../images/features/feature-10.svg';
import Feature11 from '../images/features/feature-11.png';
import Feature12 from '../images/features/feature-12.png';

const FeaturesBlock = () => {
  return (
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      <div className="feature-block">
        <img src={Feature1} alt="" />
        <div>
          <h2 className="text-2xl font-black leading-tight mb-2">Smart data search</h2>
          <p className="text-md text-gray-600 font-normal">Filter your data based on any number of criteria. If the data is anywhere on your database, adminmate will find it within seconds.</p>
        </div>
      </div>
      <div className="feature-block">
        <img src={Feature2} alt="" />
        <div>
          <h2 className="text-2xl font-black leading-tight mb-2">Dashboards & Charts</h2>
          <p className="text-md text-gray-600 font-normal">Visualise your data and make charts within minutes. This allows for quick and efficient decision-making, enabling you to focus on what matters: driving growth.</p>
        </div>
      </div>
      <div className="feature-block">
        <img src={Feature3} alt="" />
        <div>
          <h2 className="text-2xl font-black leading-tight mb-2">Cluster projects</h2>
          <p className="text-md text-gray-600 font-normal">You're part of a big company and you also run 2 small startup of yours as side projects? You can have them all on Adminmate.</p>
        </div>
      </div>
      <div className="feature-block">
        <img src={Feature4} alt="" />
        <div>
          <h2 className="text-2xl font-black leading-tight mb-2">Collaborate in real time</h2>
          <p className="text-md text-gray-600 font-normal">Interact with anyone using tools like comments, one-on-one chats, group channels, and project-specific discussions.</p>
        </div>
      </div>
      <div className="feature-block">
        <img src={Feature5} alt="" />
        <div>
          <h2 className="text-2xl font-black leading-tight mb-2">Add actions</h2>
          <p className="text-md text-gray-600 font-normal">Add actions in the form of Call To Action that you can use to immediately perform any action on your database.</p>
        </div>
      </div>
      <div className="feature-block">
        <img src={Feature6} alt="" />
        <div>
          <h2 className="text-2xl font-black leading-tight mb-2">Add teammates</h2>
          <p className="text-md text-gray-600 font-normal">Empower your team to collectively drive growth. Add as many people as you want.</p>
        </div>
      </div>
      <div className="feature-block">
        <img src={Feature7} alt="" />
        <div>
          <h2 className="text-2xl font-black leading-tight mb-2">Easily add data sources</h2>
          <p className="text-md text-gray-600 font-normal">Adminmate works great for MySQL, PostgreSQL, MongoDB and a lot more to come!</p>
        </div>
      </div>
      <div className="feature-block">
        <img src={Feature8} alt="" />
        <div>
          <h2 className="text-2xl font-black leading-tight mb-2">Export your data (csv)</h2>
          <p className="text-md text-gray-600 font-normal">Easily select and export your data so you can print them and start a fire with it because, you know, no one should print csv. Ever.</p>
        </div>
      </div>
      <div className="feature-block">
        <img src={Feature9} alt="" />
        <div>
          <h2 className="text-2xl font-black leading-tight mb-2">Custom team access</h2>
          <p className="text-md text-gray-600 font-normal">Who in their right mind would want to give the marketing guy access to all the data? Anyone?</p>
        </div>
      </div>
      <div className="feature-block">
        <img src={Feature10} alt="" />
        <div>
          <h2 className="text-2xl font-black leading-tight mb-2">Build graphs</h2>
          <p className="text-md text-gray-600 font-normal">Transforming raw numbers into visual narratives that reveal hidden patterns and trends.</p>
        </div>
      </div>
      <div className="feature-block">
        <img src={Feature11} alt="" />
        <div>
          <h2 className="text-2xl font-black leading-tight mb-2">Add ordered lists</h2>
          <p className="text-md text-gray-600 font-normal">This enables smarter, data-driven decisions and equips you to more effectively strategize, predict, and navigate your business landscape.</p>
        </div>
      </div>
      <div className="feature-block">
        <img src={Feature12} alt="" />
        <div>
          <h2 className="text-2xl font-black leading-tight mb-2">Unlimited data storage</h2>
          <p className="text-md text-gray-600 font-normal">Well, storage does not cost a lot and we needed at 12th card or this grid of features would have looked awful. So… unlimited data storage for everyone it is. SuCh InCdReDiBlE FeAtUrE 🎉</p>
        </div>
      </div>
    </div>
  );
};

export default FeaturesBlock;
